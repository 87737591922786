// scroll to benefits section
if($('.benefits__nav').length !== 0) {

    //check height header
    var header_height = $('header').outerHeight();
    $(window).on('load resize', function () {
        var header_height = $('header').outerHeight();
    });

    //on click
    $('.benefits__nav ul li a[href^="#"]').click(function() { 
        var section_id      = $(this).attr("href");
        var section_offset  = $(section_id).offset().top - header_height;
        
        $('html, body').stop().animate({scrollTop: section_offset}, 1200, 'easeInOutQuint', function() {
            $(window).on('scroll', function () {
                scroll_benefits();
            });
        });

        return false; 
    });
    
    

    //events on scroll
    function return_benefit_top_height() {
        var check_benefit_top_height = $('.benefits__top').outerHeight();
        return check_benefit_top_height;
    }
   
    $(window).on('load resize', function () {
       return_benefit_top_height();
    });

    var benefit_top_height = return_benefit_top_height();
    
    function scroll_benefits() {
        var scroll_pos = $(window).scrollTop();    

        $('.benefits__nav ul li a').each(function () {
        	var benefit_current = $(this);
        	var benefit_section = $(benefit_current.attr("href"));

            if ((benefit_section.position().top - header_height + benefit_top_height) <= scroll_pos && (benefit_section.position().top - header_height + benefit_top_height + benefit_section.height()) > scroll_pos) {
        		$('.benefits__nav ul li a').removeClass("active");
        		benefit_current.addClass("active");
        	}
        	else {
        		benefit_current.removeClass("active");
        	}      
        });
    }


    
    //move nav
    var benefit_container   = $('.benefits__wrap');
    var benefit_nav         = $('.benefits__nav');
    
    function return_benefit_nav_offset() {
       var check_benefit_nav_offset = benefit_nav.offset().top;
       return check_benefit_nav_offset;
    }

    $(window).on('load resize', function () {
       return_benefit_nav_offset();
    });

    var benefit_nav_offset = return_benefit_nav_offset();
        
    function move_nav() {
        if(window.matchMedia("(min-width:768px)").matches) { 
            var scroll_pos = $(window).scrollTop();
            var bottomFix = benefit_container.offset().top + benefit_container.outerHeight() - header_height;
            var maxY = bottomFix - benefit_nav.outerHeight();
                
            if (scroll_pos > benefit_nav_offset) {             
                if (scroll_pos < maxY) {
                    benefit_nav.removeClass('to_bottom');
                } 
                else {
                    benefit_nav.addClass('to_bottom');
                }
            } 
        }

        else {
            benefit_nav.removeClass('fixBottom');
        }
    }

    
    $(window).on('load resize scroll', function () {
        scroll_benefits();
        move_nav();
    });
}