$(document).ready(function () {

    //scrollbar custom
    $('.global_panel .has_scrollbar').each(function() {
        var scrollbar_id = $(this).attr('id');

        new SimpleBar(document.getElementById(scrollbar_id), {
            autoHide:false
        });
    });    

    
    // ouverture et fermeture panels
    $('body').on('click touch', '.js-selector-panel', function() { 
        if( !$(this).hasClass('inactive') ) {
            var button          = $(this);
            var selected_panel  = button.data('panel');
            open_selector(button, selected_panel); 
        }      
    });
    $('body').on('click touch', '.global_panel .btn_close', function() { 
        close_selector();
    });
    $('body').on('click', '.global_mask', function(e) {
        close_selector();
    });
    $('body').on('click', '.global_panel .global_mask', function(e){ 
        e.stopPropagation(); 
    });  

});



// les functions (qui peuvent etre appelées ailleurs)
    function open_selector(button, selected_panel){
        $('.js-selector-panel').removeClass('active');
        button.addClass('active');
        $('.popup_overlay_mask').fadeIn(600);        
        $('.global_panel').removeClass('is_open'); 
        $('.global_panel.'+selected_panel).addClass('is_open');
    }

    function close_selector(){
        $('.global_panel').removeClass('is_open');
        $('.js-selector-panel').removeClass('active');
        $('.popup_overlay_mask').fadeOut(600);
        $('.inspirations_filter').removeClass('open');
    }