$(document).ready(function () {
	
	$('.js-open-popup').each(function() {
		var popup_id = $(this).attr('data-popup');
		
		$(this).on('click touch', function() {
			$('#'+popup_id).fadeIn(300);
		});

		$('#'+popup_id+' .btn_close').on('click touch', function() {
			$('#'+popup_id).fadeOut(300);
		});
	});

});