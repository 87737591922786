$(document).ready(function () {

	$('.js-filter-collections').on('click', function(e) {
        e.preventDefault();

        if( !$(this).hasClass('active') ) {
            $('.js-filter-collections').toggleClass('active');
            $(this).find('img').fadeIn(300);
            $(this).addClass('active');
            var active = $(this).data('filter');  
            $('.loading_layer').addClass('loading');

            $.ajax({
                url: wami_js.ajaxurl,
                data: {
                    action: "collections_change_filter_by",
                    active: active,
                },
                success: function (data) {
                    var obj = jQuery.parseJSON(data);
                    //console.log(obj); 
                    $('.js-filter-collections').find('img').fadeOut(300);  
                    $('.js-collections-content').html(obj.content);
                    $('.loading_layer').removeClass('loading');
                    if(active=='by_colors') init_collection_products_slider();                    
                },
                error: function (errorThrown) {
                    console.log("erreur de chargement");
                },
            }); 
        }
    });

}); 


function init_collection_products_slider() {

    $('.collection_products_slider__wrap').each(function() {
        var collection_products_wrap_id = $(this).attr('id');
    
        //slider collection products
        var collection_products_slider = $('#'+collection_products_wrap_id+' .collection_products_slider .owl-carousel').owlCarousel({
            loop:true,
            autoplay:false,
            smartSpeed:1000,
            margin:30,
            nav:false,
            dots:false,
            thumbs:false,
            touchDrag:true,
            mouseDrag:false,
            lazyLoad:true,
            responsive:{
                0:{
                    items:1
                },

                768:{
                    items:3
                }
            }

        });

        
        //nav collection products
        var collection_products_slider_left = $('#'+collection_products_wrap_id+' .collection_products_slider__nav .nav_left');
        var collection_products_slider_right = $('#'+collection_products_wrap_id+' .collection_products_slider__nav .nav_right');

        collection_products_slider_left.click(function() {
            collection_products_slider.trigger('prev.owl.carousel');
        });

        collection_products_slider_right.click(function() {
            collection_products_slider.trigger('next.owl.carousel');
        });

    });

}