//WARNING temps js links li parent - ajouter les liens dans le menu acf
// $('.parent_nav__link.with-submenu').each(function(e) {
//    //e.preventDefault();
//    $(this).attr('href', 'javascript:void(0);')
// });



//select languages
function select_lang() {
   var current_lang = $('.select_lang .current-lang a').html();
   var placeholder  = $('.select_lang .select_lang__current');
   placeholder.html(current_lang);

   $('.select_lang').on('click touch', function() {
      $(this).find('ul').fadeIn(100);
   });

   $('.select_lang').on('mouseleave', function() {
      $(this).find('ul').hide();
   });
}

$(window).on('load', function () {
   select_lang();
});



//menu desktop
function desktop_menu() {
   $('.parent_nav__link').each(function() {
      var sub_menu_id   = $(this).closest('.parent_nav__li').attr('data-pid'); 
      var header        = $(this).closest('header');
      var select_lang   = $('.select_lang');

      $(this).unbind('onclick touch');

      $(this).bind('mouseenter', function() {
         $('.sub_menu_wrap').stop().hide();
         $('header').removeClass('bg_white bg_blue');
         $('.menu_overlay_mask').stop().hide();
         

         if($(this).hasClass('with-submenu')) {
            var sub_menu = $('.submenus').find('.sub_menu_wrap[data-pid="'+sub_menu_id+'"]');
        
            sub_menu.stop().fadeIn(500);

            if(sub_menu.hasClass('as-professional')) {
               header.addClass('bg_blue');
            }

            else {
               header.addClass('bg_white');
            }

            $('.menu_overlay_mask').stop().fadeIn(300);
         }

         $('.sub_menu_wrap').bind('mouseleave', function() {
            $(this).stop().hide();
            $('header').removeClass('bg_white bg_blue');
            $('.menu_overlay_mask').stop().hide();
         });
      });
   });
}



//menu mobile
function mobile_menu() {
   $('.parent_nav__link').each(function() {
      var sub_menu_id   = $(this).closest('.parent_nav__li').attr('data-pid'); 
      var header        = $(this).closest('header');

      $(this).unbind('mouseenter');

      $(this).bind('click touch', function() {
         $('.submenus').removeClass('sub_open');
         $('.sub_menu_wrap').removeClass('sub_open');
         $('header').removeClass('bg_white bg_blue');
         $('.select_lang').removeClass('is_white');
         
         if($(this).hasClass('with-submenu')) {
            var sub_menu = $('.submenus').find('.sub_menu_wrap[data-pid="'+sub_menu_id+'"]');
            
            $('.submenus').addClass('sub_open');
            sub_menu.addClass('sub_open');

            if(sub_menu.hasClass('as-professional')) {
               header.addClass('bg_blue');
            }

            else {
               header.addClass('bg_white');
            }
         }

         $('.sub_menu_wrap').unbind('mouseleave');
      });

      
      $('.sub_menu_wrap .btn_close').on('click touch', function() {
         $('.submenus, .sub_menu_wrap').removeClass('sub_open');
         $('header').removeClass('bg_white bg_blue');
      });
   });
}



//check viewport menu events
function check_responsive_menu() {
   if (window.matchMedia("(min-width:992px)").matches) { 
      $('header').removeClass('bg_white bg_blue');
      $('header nav').removeClass('smart_nav');  
      $('header nav').removeClass('open');  
      $('.burger').removeClass('open'); 
      $('.submenus, .sub_menu_wrap').removeClass('sub_open');
      
      desktop_menu();
   }

   if (window.matchMedia("(max-width:991px)").matches) {
      $('header nav').addClass('smart_nav');
      $('.menu_overlay_mask').stop().hide();
      
      mobile_menu();
   } 
}
    
$(window).on('load resize', function () {
   check_responsive_menu();
});



//burger events
$('.burger').on('click touch', function() {    
   $('.burger, .smart_nav').toggleClass('open');

   if($(this).hasClass('open')) {
      $('header').addClass('bg_white');
   }
   
   else {
      $('header').removeClass('bg_white bg_blue');
      $('header').css({'background-color':''});
      $('.submenus, .sub_menu_wrap').removeClass('sub_open');     
   }
});


//nav position on scroll
function nav_appear() {
   var header_height = $('header').outerHeight();
   
   if (window.matchMedia("(min-width:992px)").matches) { 
      var lastScrollTop = 0;
      
      var nav_appear_scroll = function() { 
         var scroll_pos = $(this).scrollTop();
         
         if(scroll_pos < lastScrollTop) {
            $('header').removeClass('is_hidden');
         }
         else {
            if(window.pageYOffset > header_height) {
               $('header').addClass('is_hidden');
            }
         }
         
         lastScrollTop = scroll_pos;
      }

      $(window).on('scroll', nav_appear_scroll);
   }

   else if (window.matchMedia("(max-width:991px)").matches) {  
      $(window).off('scroll', nav_appear_scroll);
      $('header').removeClass('is_hidden');
   } 
}

$(window).on('load resize', function () {
   nav_appear();
});