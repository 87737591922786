$(document).ready(function () {

	$('body').on('change', '.js-change-contact-form', function() {
		var optionSelected = $("option:selected", this).val();
    	$('.js-contact-form').removeClass('active');
		$('.js-contact-form[data-form="'+optionSelected+'"]').addClass('active');
	});

	// $('#wpforms-form-789').on('submit', function(){
	// 	if( $('body').find('.wpforms-confirmation-container').length ) console.log('submit'); 
	// })

}); 