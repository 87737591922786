
$(window).on("load resize", function () {
	filter_inspirations();
});

//------------------------------------------------------------------------------------
// FONCTIONS (par Julien)
// ==> A VOIR si c'est justifier de les laisser en function
//------------------------------------------------------------------------------------
//filter position on scroll
function filter_inspirations() {
	if ($(".inspirations_filter").length) {
		if (window.matchMedia("(min-width:992px)").matches) {
			$(".inspirations_filter").removeClass("open");

			var header_height = $("header").outerHeight();
			var filter_height = $(".inspirations_filter").outerHeight();
			var filter_offset = $(".inspirations_filter").offset().top;

			var filter_inspirations_scroll = function () {
				if ($("header").hasClass("is_hidden")) {
					var set_offset = filter_offset;
				} else {
					var set_offset = filter_offset - header_height;
				}
				if (window.pageYOffset > set_offset) {
					$(".inspirations_filter").addClass("sticky");
					if ($("header").hasClass("is_hidden")) {
						var pos_top = 0;
					} else {
						var pos_top = header_height;
					}
					$(".inspirations_filter.sticky").css({ top: pos_top + "px" });
					$(".inspirations")
						.find(".inspirations_top")
						.css({ "margin-top": filter_height + "px" });
				} else {
					$(".inspirations_filter.sticky").css({ top: "" });
					$(".inspirations_filter").removeClass("sticky");
					$(".inspirations")
						.find(".inspirations_top")
						.css({ "margin-top": "" });
				}
			};
			$(window).on("scroll", filter_inspirations_scroll);
		}
		if (window.matchMedia("(max-width:991px)").matches) {
			$(window).off("scroll", filter_inspirations_scroll);
			$("body .inspirations").on(
				"click touch",
				".open_inspirations_filter",
				function () {
					//$('.open_inspirations_filter').on('click touch', function() {
					$(".inspirations_filter").addClass("open");
					$(".popup_overlay_mask").fadeIn("600");
				}
			);
			$("body .inspirations").on(
				"click touch",
				".open_inspirations_filter .btn_close",
				function () {
					//$('.inspirations_filter .btn_close').on('click touch', function() {
					$(".inspirations_filter").removeClass("open");
					$(".popup_overlay_mask").fadeOut("600");
				}
			);
			$(".inspirations_filter.sticky").css({ top: "" });
			$(".inspirations_filter").removeClass("sticky");
			$(".inspirations").find(".inspirations_top").css({ "margin-top": "" });
		}
	}
}