$(document).ready(function () {

    $('body').on('click touch', '.global_media .btn_play_video', function() { 
        var video_wrap  = $(this).closest('.global_media').find('.global_media--video');
        
        if(video_wrap.hasClass('video_mp4')) {
            var video_media = $(this).closest('.global_media').find('video');
            video_wrap.fadeIn(300).css({'display':'flex'});
            video_media.trigger('play'); 
        } 

        if(video_wrap.hasClass('video_yt')) {
            var video_media = $(this).closest('.global_media').find('iframe');
            var video_src   = video_media.attr('src');
            video_media.attr('src', video_src+'?autoplay=1');
            video_wrap.fadeIn(300);
        }     
    });

});