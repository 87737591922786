var gallery_slider = false;
$(document).ready(function () {
	//slider gallery
	if ($(".global_gallery__slider .owl-carousel").length) {
		gallery_slider = $(
			"body .global_gallery__slider .owl-carousel"
		).owlCarousel({
			loop: true,
			autoplay: false,
			smartSpeed: 500,
			margin: 0,
			items: 1,
			nav: false,
			dots: false,
			touchDrag: false,
			mouseDrag: false,
			lazyLoad: true, 
			animateIn: "fadeIn",
			animateOut: "fadeOut",
		});
		gallery_slider.on("translated.owl.carousel", function () {
			history.replaceState(" ", " ", " ");
		});
	}

	//nav gallery
	$("body").on("click", ".global_gallery__slider_nav .nav_left", function () {
		gallery_slider.trigger("prev.owl.carousel");
		stop_youtube();
	});
	$("body").on("click", ".global_gallery__slider_nav .nav_right", function () {
		gallery_slider.trigger("next.owl.carousel");
		stop_youtube();
	});

	//close slider
	$("body").on("click touch", ".slide_gallery .btn_close", function () {
		$(".global_gallery__slider_wrap").fadeOut(600);
		$(".popup_overlay_mask").fadeOut(600);
	});
	$(document).mouseup(function (e) {
		var container = $(".slide_gallery");
		if (!container.is(e.target) && container.has(e.target).length === 0) {
			$(".global_gallery__slider_wrap").fadeOut(600);
			$(".popup_overlay_mask").fadeOut(600);
		}
	});
	$(".slide_gallery").on("blur", function (event) {
		$(".global_gallery__slider_wrap").fadeOut(600);
		$(".popup_overlay_mask").fadeOut(600);
	});

	//open slider
	$("body").on("click", ".global_gallery__item", function (e) {
		//e.preventDefault(); => on ne peut le mettre sinon ca plante l'ouverture du bon ID
		$("body").find(".popup_overlay_mask").fadeIn(600);
		$("body").find(".global_gallery__slider_wrap").fadeIn(600);
	});

	// Load more and filters (par marie)
	$("body").on("click", ".js-loadmore", function (e) {
		e.preventDefault();
		$(this).addClass("loading");
		load_more_and_filter(false, gallery_slider);
	});
	$("body .inspirations").on(
		"change",
		"input[type=radio][name=js-selector-pattern]",
		function (e) {
			e.preventDefault();
			$(".js-color-selector-panel").removeClass("inactive");
			load_more_and_filter("filter", gallery_slider);
			close_selector();
		}
	);
	$("body .inspirations").on(
		"change",
		"input[type=radio][name=js-selector-market]",
		function (e) {
			e.preventDefault();
			load_more_and_filter("filter", gallery_slider);
			close_selector();
		}
	);
	$("body .inspirations").on(
		"change",
		"input[type=radio][name=js-selector-color]",
		function (e) {
			e.preventDefault();
			load_more_and_filter("filter", gallery_slider);
			close_selector();
		}
	);
	$("body .inspirations").on(
		"change",
		"input[type=radio][name=js-selector-inspi]",
		function (e) {
			e.preventDefault();
			load_more_and_filter("filter", gallery_slider);
		}
	);
	$("body .inspirations").on("click", ".js-clear-filters", function (e) {
		e.preventDefault();
		load_more_and_filter("clear", gallery_slider);
	});
});

// POURQUOI ICI ?
$(window).on("load resize", function () {
	gallery_slider_events();
	init_push_gallery_slider();
});

//------------------------------------------------------------------------------------
// FONCTIONS (par Julien)
// ==> A VOIR si c'est justifier de les laisser en function
//------------------------------------------------------------------------------------

// Push gallery slider
function init_push_gallery_slider() {
	if ($(".js_push_gallery .global_gallery__list .row").length) {
		var check_width = $(window).width();
		var owl_wrapper = $(".js_push_gallery .global_gallery__list .row");
		if (check_width >= 768) {
			if (typeof owl_wrapper.data("owl.carousel") != "undefined") {
				owl_wrapper.data("owl.carousel").destroy();
			}
			owl_wrapper.removeClass("owl-carousel");
		} else if (check_width < 768) {
			owl_wrapper.addClass("owl-carousel");
			owl_wrapper.owlCarousel({
				loop: true,
				autoplay: false,
				smartSpeed: 1000,
				margin: 20,
				items: 1,
				nav: false,
				dots: false,
				touchDrag: true,
				mouseDrag: false,
				lazyLoad: true,
				dots: false,
			});
		}
	}
}

//events on slide
function gallery_slider_events() {
	if ($(".slide_gallery").length) {
		$(".slide_gallery").each(function () {
			var infos_wrap = $(this).find(".slide_gallery__infos");
			var infos_desc = $(this).find(".infos_desc");
			var infos_btn = $(this).find(".btn_infos");
			var infos_overlay = $(this).find(".infos_overlay");
			//infos on hover
			if (window.matchMedia("(min-width:768px)").matches) {
				$(this)
					.not("button", infos_wrap)
					.bind("mouseenter", function () {
						infos_desc.addClass("is_open").slideDown(300);
					});
				$(this)
					.not("button", infos_wrap)
					.bind("mouseleave", function () {
						infos_desc.removeClass("is_open").slideUp(300);
						infos_overlay.stop().fadeOut(300);
					});
				//show infos sup
				infos_wrap.bind("mouseenter", function () {
					infos_overlay.stop().slideDown(300);
				});
				//hide infos sup
				infos_wrap.bind("mouseleave", function () {
					infos_overlay.stop().fadeOut(300);
				});
			} else if (window.matchMedia("(max-width:767px)").matches) {
				$(this).not("button").unbind("mouseenter");
				$(this).not("button").unbind("mouseleave");
				infos_wrap.unbind("mouseenter");
				infos_wrap.unbind("mouseleave");
				infos_desc.removeClass("is_open");
				infos_desc.show();
				infos_wrap.show();
				infos_overlay.show();
				infos_overlay.attr("style", " ");
			}
		});
	}
}

function stop_youtube() {
	$(".slide_gallery.has_video").each(function () {
		var video = $(this).find("iframe");
		var video_src = video.attr("src");
		video.attr("src", " ");
		setTimeout(function () {
			video.attr("src", video_src);
		}, 100);
	});
}

//------------------------------------------------------------------------------------
// FONCTIONS (par Marie)
//------------------------------------------------------------------------------------

function load_more_and_filter(filter = false, gallery_slider = false) {
	$(".loading_layer").addClass("loading");
	$(".loading_filter").addClass("loading");
	$(".js-inspirations-filters .svg_loader").show();
	$(".js-inspirations-filters .btn_filter").hide();

	var post_type = $(".js-loadmore").data("post-type");
	var nb_post_to_display = $(".js-loadmore").data("nb-post-to-display");
	var nb_post_displayed = $(".js-loadmore").data("nb-post-displayed");
	var nb_post_total = $(".js-loadmore").data("nb-post-total");
	var exclude_post = $(".js-loadmore").data("ex-post");
	var selected_pattern = $(
		"input[type=radio][name=js-selector-pattern]:checked"
	).data("tid");
	var selected_color = $(
		"input[type=radio][name=js-selector-color]:checked"
	).data("tid");
	var selected_m_segment = $(
		"input[type=radio][name=js-selector-market]:checked"
	).data("tid");
	var selected_inspi_type = $(
		"input[type=radio][name=js-selector-inspi]:checked"
	).val();
	if (filter) {
		var nb_post_displayed = 0;
		var exclude_post = false;
		if (filter == "clear") {
			var selected_pattern = false;
			var selected_color = false;
			var selected_m_segment = false;
			var selected_inspi_type = false;
		}
	}


	$.ajax({
		url: wami_js.ajaxurl,
		data: {
			action: "wami_loadmore_and_filtre",
			post_type: post_type,
			nb_post_displayed: nb_post_displayed,
			nb_post_to_display: nb_post_to_display,
			exclude_post: exclude_post,
			pattern: selected_pattern,
			color: selected_color,
			market_segment: selected_m_segment,
			selected_inspi_type: selected_inspi_type,
			filter_action: filter,
		},
		success: function (data) {
			var obj = jQuery.parseJSON(data); //console.log(obj);
			$(".loading_layer").removeClass("loading");
			$(".loading_filter").removeClass("loading");
			$(".js-inspirations-filters .svg_loader").hide();
			$(".js-inspirations-filters .btn_filter").show();

			// on ajoute les contenu selon qu'on est en train de filter ou non
			if (filter) {				
				window.scrollTo(0, 0); //scrolltop
				if (filter == "clear") {
					$(".js-inspirations-filters").html(obj.filters);
				}
				$(".js-append-content").html(obj.content);
				if (obj.slider_content.length > 0) {
					var newContent = "";
					$.each(obj.slider_content, function (key, val) {
						newContent += val["content"];
					});
					gallery_slider.trigger("replace.owl.carousel", newContent).trigger('refresh.owl.carousel');
				}
			} else {
				$(".js-append-content").append(obj.content);
				$.each(obj.slider_content, function (key, val) {
					gallery_slider.trigger("add.owl.carousel", [jQuery(val["content"])]);
				});
			}

			// on modifie les boutons et les couleurs dispo si on en a un (cad si on a changé de pattern)
			if (obj.colors_panel != false || obj.colors_panel != "")
				$(".js-color-global-panel").replaceWith(obj.colors_panel);
			if (obj.colors_button != false || obj.colors_button != "")
				$(".js-color-selector-panel").replaceWith(obj.colors_button);
			if (obj.patterns_button != false || obj.patterns_button != "")
				$(".js-pattern-selector-panel").replaceWith(obj.patterns_button);
			if (obj.markets_button != false || obj.markets_button != "")
				$(".js-ms-selector-panel").replaceWith(obj.markets_button);

			// on update le bouton de loadmore
			nb_post_displayed = nb_post_displayed + nb_post_to_display;
			if (
				obj.post_to_exclude == "" ||
				(nb_post_total && nb_post_displayed >= nb_post_total)
			)
				$(".js-loadmore").hide();
			else $(".js-loadmore").show();
			$(".js-loadmore").data("nb-post-displayed", nb_post_displayed);
			$(".js-loadmore").data("ex-post", obj.post_to_exclude);
			$(".js-loadmore").removeClass("is-active");
		},
		error: function (errorThrown) {
			console.log("erreur de chargement");
		},
	});
}
