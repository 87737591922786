$(document).ready(function () {

	$('body .wallcovering').on('change', 'input[type=radio][name=js-selector-pattern]', function(e) { 
		var selected_pattern 	= $(this).data('tid'); 
		udpate_wallcovering_displayer(selected_pattern, 0);
	});


	$('body .wallcovering').on('change', 'input[type=radio][name=js-selector-color]', function(e) {
		var selected_pattern 	= $('input[type=radio][name=js-selector-pattern]:checked').data('tid');
		var selected_color 		= $(this).data('tid');
		udpate_wallcovering_displayer(selected_pattern, selected_color);
	}); 


	$('body .wallcovering').on('change', 'input[type=radio][name=js-selector-ambient]', function(e) {        
		var new_ambient = $(this).val();
		var new_name 	= $(this).data('name');
		$('.js-wallcovering-displayer-cover').attr('src', new_ambient).attr('alt', new_name);
        close_selector();
	});	


	function udpate_wallcovering_displayer(selected_pattern, selected_color){
        // console.log(selected_pattern, 'selected_pattern');
        // console.log(selected_color, 'selected_color');
        close_selector();
		$.ajax({
            url: wami_js.ajaxurl,
            data: {
                action: "wallcovering_displayer_change_colors",
                selected_pattern: selected_pattern,
                selected_color: selected_color
            },
            success: function (data) {
                var obj = jQuery.parseJSON(data);
                // console.log(obj.selected_pattern, 'pattern');
                // console.log(obj.selected_color, "color");
                // console.log(obj.new_wallcovering_bg);
                // console.log(obj.pid);
                // console.log(obj.test);
                // $('.js-selector-panel-pattern-title').html(newhtml);
                if(obj.new_color_selector  != '') $('.js-selector-color').replaceWith( obj.new_color_selector );    
                if(obj.new_wallcovering_bg != false) $('.js_wallcovering-container').css('background-image','url("'+obj.new_wallcovering_bg+'")');
                if(obj.colors_panel != false) $('.js-color-global-panel').replaceWith( obj.colors_panel ); 
                if(obj.buttons != false) $('.js-panel-filters-buttons').html( obj.buttons ); 
                if($('.js-home-product_detail').length) {
                	$('.js-wallcovering-displayer-description').html( obj.description );
                }
                hide_selectors_on_mobile(); 
            },
            error: function (errorThrown) {
                console.log("erreur de chargement");
            },
        });        
	}

    
    //selecteur mobile
    $('.wallcovering__call_button button').on('click touch', function() {
        $('.wallcovering__call_button').hide();
        $('.wallcovering__buttons_wrap').slideDown(300);
    });
    

    function hide_selectors_on_mobile() {
        if (window.matchMedia("(max-width:991px)").matches) {
            $('.wallcovering__buttons_wrap').hide();
            $('.wallcovering__call_button').fadeIn(300);
        }
        else {
            $('.wallcovering__buttons_wrap').css({'display':''});
            $('.wallcovering__call_button').css({'display':''});
        }
    }

    $(window).on('load resize', function () {
       hide_selectors_on_mobile();
    });

});