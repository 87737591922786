$(document).ready(function () {
	
	// Benefits
	if($('.push_benefits__slider .owl-carousel').length){
		//console.log('push_benefits__slider');
		var benefits_slider = $('.push_benefits__slider .owl-carousel').owlCarousel({
			loop:true,
			autoplay:true,
			autoplayTimeout:5000,
			autoplaySpeed:800,
			smartSpeed:800,
			autoplayHoverPause:true,
			margin:25,
			nav:false,
			touchDrag:true,
			mouseDrag:true,
			lazyLoad:true,
			responsive:{
				0:{
					items:1,
					dots:true
				},

				992:{
					items:3,
					dots:false
				}
			}
		});
	}


	// Segments
	if($('.segments_slider_sync1 .owl-carousel').length){
		//console.log('segments_slider_sync1');
		var segments_slider_sync1 = $('.segments_slider_sync1 .owl-carousel').owlCarousel({
			autoplay:false,
			smartSpeed:1000,
			nav:false,
			dots:false,
			mouseDrag:false,
			touchDrag:false,
			lazyLoad:true,
			items:1,
			margin:0,
			responsive:{
				0:{
					loop:false,
					animateIn:'fadeIn',
					animateOut:'fadeOut'
				},

				768:{
					loop:true,
					animateIn:'slideInRight',
					animateOut:'slideOutLeft'
				}
			}
		});
		var segments_slider_sync2 = $('.segments_slider_sync2 .owl-carousel').owlCarousel({
			autoplay:false,
			smartSpeed:1000,
			nav:false,
			dots:false,
			mouseDrag:false,
			lazyLoad:true,
			items:1,
			thumbs:true,
			thumbsPrerendered:true,
			thumbContainerClass:'bloc_segment__titles',
			thumbItemClass:'bloc_segment__title',
			responsive:{
				0:{
					margin:10,
					loop:false,
					touchDrag:true,
					mousedrag:true
				},

				768:{
					margin:0,
					loop:true,
					touchDrag:false,
					mouseDrag:false
				}
			}
		});
		//nav
		var segments_slider_left = $('.segments_slider_sync2 .segments_slider__nav .nav_left');
		var segments_slider_right = $('.segments_slider_sync2 .segments_slider__nav .nav_right');
		segments_slider_left.bind('click', function() {
			segments_slider_sync1.trigger('prev.owl.carousel');
			segments_slider_sync2.trigger('prev.owl.carousel');
		});
		segments_slider_right.bind('click', function() {
			segments_slider_sync1.trigger('next.owl.carousel');
			segments_slider_sync2.trigger('next.owl.carousel');
		});
		if (window.matchMedia("(max-width:767px)").matches) {
			segments_slider_sync2.bind('dragged.owl.carousel', function(event) {
				if (event.relatedTarget['_drag']['direction'] == "left") {
					segments_slider_sync1.trigger("next.owl.carousel");
				} 
				else {
					segments_slider_sync1.trigger("prev.owl.carousel");
				}
			});
		}
	}


	// Product
	if($('.product_slider .owl-carousel').length){
		//console.log('product_slider');
		var product_slider = $('.product_slider .owl-carousel').owlCarousel({
			loop:true,
			autoplay:false,
			smartSpeed:1000,
			margin:0,
			items:1,
			nav:false,
			dots:false,
			touchDrag:true,
			mouseDrag:true,
			lazyLoad:true,
			dots:false,
	    	animateIn: 'fadeIn',
	    	animateOut: 'fadeOut'
		});		
		//nav
		var product_slider_left = $('.product_slider__nav .nav_left');
		var product_slider_right = $('.product_slider__nav .nav_right');
		product_slider_left.click(function() {
			product_slider.trigger('prev.owl.carousel');
		});
		product_slider_right.click(function() {
			product_slider.trigger('next.owl.carousel');
		});
	}


	// Collections
	if($('.collection_content__group').length){
		//console.log('collection_content__group');
		$('.collection_content__group').each(function() {
			var collection_group_id = $(this).attr('id');		
			//slider gallery
			var color_slider = $('#'+collection_group_id+' .color_slider .owl-carousel').owlCarousel({
				loop:true,
				autoplay:false,
				smartSpeed:1000,
				items:1,
				nav:false,
				dots:false,
				thumbs:true,
				thumbsPrerendered:true,
				mouseDrag:false,
				thumbContainerClass:'color_slider__thumbs',
				thumbItemClass:'color_thumb',
				lazyLoad:true,
				/*animateIn: 'fadeIn',
		    	animateOut: 'fadeOut',*/
		    	responsive:{
					0:{
						margin:0,
						touchdrag:true
					},

					768:{
						margin:0,
		    			touchdrag:false
					}
				}
			});			
			//nav gallery
			var color_slider_left = $('#'+collection_group_id+' .color_slider__nav .nav_left');
			var color_slider_right = $('#'+collection_group_id+' .color_slider__nav .nav_right');
			color_slider_left.click(function() {
				color_slider.trigger('prev.owl.carousel');
			});
			color_slider_right.click(function() {
				color_slider.trigger('next.owl.carousel');
			});
		});
	 }


	// collections products
	if($('.collection_products_slider__wrap').length){
		//console.log('collection_products_slider__wrap');
		$('.collection_products_slider__wrap').each(function() {
			var collection_products_wrap_id = $(this).attr('id');		
			//slider collection products
			var collection_products_slider = $('#'+collection_products_wrap_id+' .collection_products_slider .owl-carousel').owlCarousel({
				loop:true,
				autoplay:false,
				smartSpeed:1000,
				margin:30,
				nav:false,
				dots:false,
				thumbs:false,
				touchDrag:true,
				mouseDrag:false,
				lazyLoad:true,
				responsive:{
					0:{
						items:1
					},

					768:{
						items:3
					}
				}
			});			
			//nav collection products
			var collection_products_slider_left = $('#'+collection_products_wrap_id+' .collection_products_slider__nav .nav_left');
			var collection_products_slider_right = $('#'+collection_products_wrap_id+' .collection_products_slider__nav .nav_right');
			collection_products_slider_left.click(function() {
				collection_products_slider.trigger('prev.owl.carousel');
			});
			collection_products_slider_right.click(function() {
				collection_products_slider.trigger('next.owl.carousel');
			});
		});	

		// collection products ajax
		$('.collection_products_slider__wrap').each(function() {
			var collection_products_wrap_id = $(this).attr('id');	
			//slider collection products
			var collection_products_slider = $('#'+collection_products_wrap_id+' .collection_products_slider .owl-carousel').owlCarousel({
				loop:true,
				autoplay:false,
				smartSpeed:1000,
				margin:30,
				nav:false,
				dots:false,
				thumbs:false,
				touchDrag:true,
				mouseDrag:false,
				lazyLoad:true,
				responsive:{
					0:{
						items:1
					},

					768:{
						items:3
					}
				}
			});	
			//nav collection products
			var collection_products_slider_left = $('#'+collection_products_wrap_id+' .collection_products_slider__nav .nav_left');
			var collection_products_slider_right = $('#'+collection_products_wrap_id+' .collection_products_slider__nav .nav_right');
			collection_products_slider_left.click(function() {
				collection_products_slider.trigger('prev.owl.carousel');
			});
			collection_products_slider_right.click(function() {
				collection_products_slider.trigger('next.owl.carousel');
			});
		});
	}

});


