$(document).ready(function () {

	$('body').on('mouseenter', '.js-get-collection ul li a', function() {
		if(!$(this).hasClass('active')) {

			var collection_img 			= $(this).find('.list_img').attr('src');
			var collection_title 		= $(this).find('h3').html();
			var collection_desc 		= $(this).find('p').html();

			var collection_cover_img 	= $('.push_collections--cover').find('img');
			var collection_cover_title 	= $('.push_collections--cover').find('.cover_desc h3');
			var collection_cover_desc 	= $('.push_collections--cover').find('.cover_desc p');

			collection_cover_img.hide().attr('src', collection_img).fadeIn(300);
			collection_cover_title.hide().html(collection_title).fadeIn(300);
			collection_cover_desc.hide().html(collection_desc).fadeIn(300);

			$('.js-get-collection ul li a').removeClass('active');
			$(this).addClass('active');

			return false;
		}
	});

});


function load_first_collection() {
	var first_collection 		= $('.js-get-collection ul li').first().find('a');
	var collection_img 			= first_collection.find('.list_img').attr('src');
	var collection_title 		= first_collection.find('h3').html();
	var collection_desc 		= first_collection.find('p').html();

	var collection_cover_img 	= $('.push_collections--cover').find('img');
	var collection_cover_title 	= $('.push_collections--cover').find('.cover_desc h3');
	var collection_cover_desc 	= $('.push_collections--cover').find('.cover_desc p');

	collection_cover_img.attr('src', collection_img);
	collection_cover_title.html(collection_title);
	collection_cover_desc.html(collection_desc);

	$('.js-get-collection ul li a').removeClass('active');
	first_collection.addClass('active');
}

window.addEventListener("load", load_first_collection, false);