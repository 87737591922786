$(document).ready(function () {

	$('.js-roll-generator').on('keyup', 'input', function(e) { 
                var length      = $(".js-roll-generator input[name='roll_length']:checked").val();	 
                var surface     = $(".js-roll-generator input[name='surface']").val(); 
                var width       = $(".js-roll-generator input[name='roll_width']").val(); 
                var width_type  = $(".js-roll-generator input[name='roll_width']").attr('type'); 
                if(width_type=='radio') width = $(".js-roll-generator input[name='roll_width']:checked").val();     
                if(length && surface) update_roll_generator(length, width, surface);
                else $('.js-result p, .js-result a').hide();
	});


	function update_roll_generator(length, width, surface){
                var label       = $('.js-result').data('label');
                var label_l     = $('.js-dl-pdf').data('label_l');
                var label_w     = $('.js-dl-pdf').data('label_w');
                var print       = $('.js-result').data('print');
                //var mult        = 9.8490; // si length 10m (avant)                
                var calcul = surface / length; //surface / mult; 
                var result = Math.ceil(calcul); 
                var extralabel = '';
                
                if (length == 50 && parseInt(surface) > length ) {
                        var decimal = result_decimal(calcul); 
                        //console.log('decimal = '+decimal);
                        if( decimal > 0 && decimal < 0.41 ) {
                                result = result - 1;
                                if(decimal < 0.21) extralabel = '<br>+1 roll of 10m';
                                else if(decimal < 0.41) extralabel = '<br>+2 rolls of 10m';
                        }
                }
                if (length == 10) {
                        result = result + 1; 
                } 
                
                if ( result < 1 ) result = 1;
                else if( result > 1 ) label = label+'s';
                label = label + extralabel;

                $('.js-result p').html(result+' '+label).show();
                $('.js-result a').attr("href", print).show();
                // et on doit ici push les datas attribut du bouton pour les avoir dans le pdf
                $('.js-dl-pdf').data('surface', surface).data('rolls_total', result+' '+label).data('length', length+' '+label_l).data('width', width+' '+label_w); 
	}


        function result_decimal(num) {
            var arrondi = Math.ceil(num);
            return 1 + (num - arrondi);
        }
});