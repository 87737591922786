$(document).ready(function () {

	//accordions
	$('.js-accordion-main').each(function() {
		var main_accordion = $(this);
		var main_accordion_head 	= $(this).find('.accordion--head').first();
		var main_accordion_btn 		= $(this).find('.accordion--btn').first();
		var main_accordion_content	= $(this).find('.accordion--content_wrap').first();

		main_accordion_head.on('click touch' , function() {
			if(main_accordion.hasClass('open')) {
				main_accordion.removeClass('open');
				main_accordion_btn.removeClass('open');
				main_accordion_content.stop().slideUp(200);
			}

			else {
				$('.js-accordion-main').removeClass('open');
				$('.js-accordion-main').find('.accordion--btn').removeClass('open');
				$('.js-accordion-main').find('.accordion--content_wrap').stop().slideUp(200);
				
				main_accordion.addClass('open');
				main_accordion_btn.addClass('open');
				main_accordion_content.stop().slideDown(200);
			}

			$('.js-accordion-sub').removeClass('open');
			$('.js-accordion-sub').find('.accordion--btn').removeClass('open');
			$('.js-accordion-sub').find('.accordion--content_wrap').stop().slideUp(200);
		});
	});

	$('.js-accordion-sub').each(function() {
		var sub_accordion = $(this);
		var sub_accordion_head 		= $(this).find('.accordion--head');
		var sub_accordion_btn 		= $(this).find('.accordion--btn');
		var sub_accordion_content	= $(this).find('.accordion--content_wrap');

		sub_accordion_head.on('click touch' , function() {
			if(sub_accordion.hasClass('open')) {
				sub_accordion.removeClass('open');
				sub_accordion_btn.removeClass('open');
				sub_accordion_content.stop().slideUp(200);
			}

			else {
				$('.js-accordion-sub').removeClass('open');
				$('.js-accordion-sub').find('.accordion--btn').removeClass('open');
				$('.js-accordion-sub').find('.accordion--content_wrap').stop().slideUp(200);
					
				sub_accordion.addClass('open');
				sub_accordion_btn.addClass('open');
				sub_accordion_content.stop().slideDown(200);
			}
		});
	});	
});


//scroll map to infos
$(window).on('load resize', function () {
	if($('.distributors__infos').length != 0) {

		var check_width = $(window).width();
		var distributors_infos_offset = $('.distributors__infos').offset().top;
		var header_height = $('header').outerHeight();

		if (check_width <= 991) {
			var set_offset = distributors_infos_offset - header_height;
		}

		else if (check_width > 991) { 
			var set_offset = distributors_infos_offset;
		}

		$('body').on('click touch', '.distributors__map--btns button', function() {
			var region = $(this).data('region');
			$('html, body').stop().animate({scrollTop: set_offset }, 1200, 'easeInOutQuint', function() {
				change_loc(region);
			});
			return false;
		});

		$('body').on('click touch', '.js-btn-loc', function() {
			var region = $(this).data('region');	
			change_loc(region);	
		});

	}

});	



	function change_loc(region){
		//console.log(region);
		// le bouton du continent
		$('.distributors__infos .nice_nav ul li .js-btn-loc').removeClass('active');
		$('.distributors__infos .nice_nav ul li .js-btn-loc[data-region="'+region+'"]').addClass('active');
		// le block de contenu du continent
		$('.distributors__infos .js-accordion-parent').removeClass('active');
		$('.distributors__infos .js-accordion-parent[data-region="'+region+'"]').addClass('active');
	}
	